

import react, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../asserts/logo3.png'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import { Dropdown, DropdownButton, Form, Offcanvas } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'
import { useConnect, useWalletClient } from 'wagmi';
import Web3 from 'web3';
import { setAccount, setAccounts } from '../reduces/account';
import { useDispatch } from 'react-redux'
import { addDays } from 'flowbite-react/lib/esm/components/Datepicker/helpers';
// import token from '../asserts/'

function Headers({ account, balance }) {

    console.log(account, balance, 'account,balanceaccount,balanceaccount,balance');

    const [show, setShow] = useState(false);
    const [address, setAddress] = useState('')
    const dispatch = useDispatch()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const handleCloseOffcanvas = () => setShowOffcanvas(false);
    const handleShowOffcanvas = () => setShowOffcanvas(true);

    const navigate = useNavigate()
    const { data: walletClient } = useWalletClient(80001);
    const { connect, connectors } = useConnect();



    async function checkConnect() {
        const { chain, transport } = walletClient;
        // contractsService.loadContracts(transport);
        // console.log(account, "account");
        if (walletClient) {

            // const network = {
            //     chainId: chain.id,
            //     name: chain.name,
            //     ensAddress: chain.contracts?.ensRegistry?.address,
            // };

            // const provider = new BrowserProvider(transport, network);
            // const signer = new JsonRpcSigner(provider, account.address);
            var web3 = await new Web3(transport);

            // const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            var result = await web3.eth.getAccounts();
            console.log(result, "");
            await setAddress(result[0])
            var networks = await web3.eth.getChainId();


            console.log(networks, 'networksnetworksnetworks');

            if (networks == 80001) {

                // bal = await toFixedFormat(bal);
                // setbalance(bal);
                // console.log(bal, result[0]);
                // setuseraddress(result[0]);
                await dispatch(
                    setAccounts({
                        network: chain.id,
                        web3: transport,
                        address: result[0],
                        provider: transport,
                        connect: "yes",
                        userdetails: '',

                    })
                );
                localStorage.setItem("WheelAddress", result[0])

                // toastAlert('success', "Wallet connected successfully", 'network');
            } else {
                // toastAlert("error", "please select BSC chain network", "network");
                await dispatch(
                    setAccounts({
                        network: "",
                        web3: "",
                        address: "",
                        provider: "",
                        connect: "no",
                        userdetails: "",
                    })
                );
            }


        }
    }

    useEffect(() => {
        if (walletClient) {
            checkConnect()
        }

    }, [walletClient])

    const connectFunction = async (connector) => {

        try {
            let check = isMobile();
            if (
                check &&
                !window.ethereum &&
                connector &&
                connector.connector.id != "walletConnect" &&
                connector.connector.id == "metaMask"
            ) {
                await connectMetamaskMobile();
                return;
            }
            console.log(window.ethereum, 'connectFunction');
            if (window.ethereum) {
                let web3 = new Web3(window.ethereum);
                let currnetwork = await web3.eth.net.getId();
                console.log(currnetwork, "afdefewf");
                if (
                    parseInt(currnetwork) != 80001 &&
                    connector.connector.id == "metaMask"
                ) {
                    alert("Please connect Polygon Mainnet");
                    return;
                }
            }

            console.log(connector);
            try {

                var resp = await connect(connector);

                setTimeout(function () {
                    if (connector.connector.id != "walletConnect") {
                        navigate('/')
                    }
                }, 600);
            } catch (error) {
                console.log(error);
            }
        } catch (err) {
            console.log(err, 'errrrrr');
        }
    };

    function isMobile() {
        let check = false;
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4)
                )
            )
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }

    const connectMetamaskMobile = () => {
        const dappUrl = window.location.href.split("//")[1].split("/")[0];
        const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
        window.open(metamaskAppDeepLink, "_self");
    };


    const logOutHandler = async () => {
        console.log('logOutHandler');
        await dispatch(
            setAccounts({
                network: "",
                web3: "",
                address: "",
                provider: "",
                connect: "no",
                userdetails: "",
            })
        );

        await setAddress('')
        await localStorage.removeItem("WheelAddress")
    }

    return (

        <div>
            <Container fluid style={{ backgroundColor: 'gray' }}>

                <Row style={{ padding: '6px' }}>
                    <Col xs={3} md={1} >

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>

                            <Button variant="dark" onClick={handleShowOffcanvas}>
                                <i class="fa fa-bars" aria-hidden="true"></i>
                            </Button>
                        </div>

                        <Offcanvas style={{ width: '300px' }} show={showOffcanvas} onHide={handleCloseOffcanvas} >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>Game</Offcanvas.Title>
                            </Offcanvas.Header>
                            <hr />
                            <Offcanvas.Body>
                                <span>
                                    <i class='fab fa-xbox'></i>
                                    <Link style={{ textDecorationLine: 'none' }} to={'/spinner'}>
                                        Spinner Game
                                    </Link>
                                </span>

                            </Offcanvas.Body>
                        </Offcanvas>

                    </Col>
                    <Col xs={3} md={2}>
                        <div style={{ display: 'flex', justifyContent: 'start', textAlign: 'center' }}>

                            <img style={{ width: '75px' }} src={logo} alt='logo' />
                        </div>
                    </Col>
                    <Col xs={3} md={4}>

                        <div style={{ display: 'flex', justifyContent: 'start', textAlign: 'center', marginTop: '20px' }}>

                            <InputGroup>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="currentColor" class="bi bi-currency-bitcoin" viewBox="0 0 16 16">
                                    <path d="M5.5 13v1.25c0 .138.112.25.25.25h1a.25.25 0 0 0 .25-.25V13h.5v1.25c0 .138.112.25.25.25h1a.25.25 0 0 0 .25-.25V13h.084c1.992 0 3.416-1.033 3.416-2.82 0-1.502-1.007-2.323-2.186-2.44v-.088c.97-.242 1.683-.974 1.683-2.19C11.997 3.93 10.847 3 9.092 3H9V1.75a.25.25 0 0 0-.25-.25h-1a.25.25 0 0 0-.25.25V3h-.573V1.75a.25.25 0 0 0-.25-.25H5.75a.25.25 0 0 0-.25.25V3l-1.998.011a.25.25 0 0 0-.25.25v.989c0 .137.11.25.248.25l.755-.005a.75.75 0 0 1 .745.75v5.505a.75.75 0 0 1-.75.75l-.748.011a.25.25 0 0 0-.25.25v1c0 .138.112.25.25.25zm1.427-8.513h1.719c.906 0 1.438.498 1.438 1.312 0 .871-.575 1.362-1.877 1.362h-1.28V4.487zm0 4.051h1.84c1.137 0 1.756.58 1.756 1.524 0 .953-.626 1.45-2.158 1.45H6.927V8.539z" />
                                </svg>


                                <Form.Control
                                    // aria-label="Example text with button addon"
                                    // aria-describedby="basic-addon1"
                                    style={{ backgroundColor: 'transparent', border: 'none' }}
                                    // color="transparent"
                                    value={balance / 1e18}
                                    disabled
                                />

                                <Button style={{ width: '200px', boxShadow: '0px 0px 5px 2px #585a5d' }} variant="secondary" id="button-addon1"
                                    onClick={() => navigate('/wallet')}
                                >
                                    Wallet
                                </Button>
                            </InputGroup>
                        </div>
                    </Col>
                    <Col md={1}>
                    </Col>

                    {
                        address && address != '' ? <Col xs={3} md={3} style={{ marginTop: '25px' }}>
                            <div style={{ display: 'flex', justifyContent: 'end', textAlign: 'end' }}>
                                <DropdownButton variant="dark" id="dropdown-basic-button" title={`${address.slice(0, 5)}...${address.slice(address.length - 5)}`}>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={() => logOutHandler()}>LogOut</Dropdown.Item>
                                </DropdownButton>



                            </div>
                        </Col> : <Col xs={4} md={3} style={{ marginTop: '25px' }}>
                            <div style={{ display: 'flex', justifyContent: 'end', textAlign: 'end' }}>

                                <Button variant="dark" onClick={handleShow}>
                                    Connect Wallet
                                </Button>
                            </div>
                        </Col>

                    }



                </Row>


            </Container>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ marginBottom: '20px' }}>
                        Woohoo, you are reading this text in a modal!
                    </p>

                    <div>

                        {connectors &&
                            connectors.length > 0 &&
                            connectors.map((connector) => {

                                return (
                                    <>
                                        {connector && connector.id == "metaMask" ? (

                                            <Button style={{ width: "100%", marginBottom: '15px', boxShadow: '0px 0px 5px 2px #585a5d' }} variant="light" onClick={() => connectFunction({ connector })} >

                                                MetaMask Wallet

                                            </Button>
                                        ) : connector && connector.id == "walletConnect" ? (
                                            <a target="_blank" className="d-block">
                                                <Button style={{ width: "100%", boxShadow: '0px 0px 5px 2px #585a5d' }} variant="primary"
                                                    onClick={() => connectFunction({ connector })}>

                                                    Trust Wallet

                                                </Button>
                                            </a >
                                        ) : (
                                            ""
                                        )}
                                    </>
                                );
                            })}



                        {/* <Button style={{ width: "100%", marginBottom: '15px', boxShadow: '0px 0px 5px 2px #585a5d' }} variant="light" onClick={handleClose}>
                            MetaMask
                        </Button>
                        <Button style={{ width: "100%", boxShadow: '0px 0px 5px 2px #585a5d' }} variant="primary" onClick={handleClose}>
                            Trust Wallet
                        </Button> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>


        </div >
    )

}


export default Headers