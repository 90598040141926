

import { createSlice } from '@reduxjs/toolkit'

let initialState = {
    network: '',
    web3: '',
    address: '',
    provider: '',
    connect: "",
    userdetails: '',
}

const AccountSlice = createSlice({
    name: 'Account',
    initialState,
    reducers: {
        setAccounts: (state, action) => {
            state = action.payload
            return state
        }
    }
})


export const { setAccounts } = AccountSlice.actions
export default AccountSlice.reducer
