import { combineReducers } from "redux";
import Account from './account'


const mainReducer = (asyncReducers) => (state, action) => {
  const combinedReducers = combineReducers({
    Account,
    ...asyncReducers,
  });

  return combinedReducers(state, action);
};

export default mainReducer;