

export const FieldValidation = async (value) => {


    let error = {}
    const REgex = /^[1-9]\d*(\.\d+)?$/
    if (value == '') {
        error.Message = 'Value Is Required'
    } if (value <= 0) {
        error.Message = 'Value Must be Greater then Zero'
    } else if (REgex.test(value)==false) {
        error.Message = 'Value Must be only Numbers'
    }
    return error
}