

import react from 'react'
import Web3, { Contract } from 'web3'
import tokenABI from './ABI/tokenABI.json'
import casinoABI from './ABI/casinoABI.json'
import config from './lib/config'
import { convert } from './action'

const web3 = new Web3(window.ethereum)


export async function GetAccountBalance(address) {
    const AccBal = await web3.eth.getBalance(address)
    console.log(AccBal, 'AccBalAccBalAccBal');
    return AccBal / 1e18
}
export async function GetTokenBalance(acc) {

    const TokenContract = new web3.eth.Contract(tokenABI, config.tokenAddress)
    console.log(TokenContract, acc, 'TokenContractTokenContractTokenContract');

    const tokenBal = await TokenContract.methods.balanceOf(acc).call()
    return tokenBal
}


export const getTokenPrice = async () => {


    const casinoContract = await new web3.eth.Contract(casinoABI, config.CasinoAddress)
    console.log(casinoContract, 'casinoContractcasinoContractcasinoContractcasinoContract');
    const price = await casinoContract.methods.convertValue('1').call();
    console.log(price, 'pricepricepricepricepriceprice');
    return parseFloat(price)
}



export const BuyNft = async (amount, tokens, address) => {
    try {

        if (window.ethereum) {

            const web3 = new Web3(address.provider)
            console.log(amount, tokens, address.address, 'amount,tokens,addressamount,tokens,address');

            const totalToken = await convert(amount)
            const TOtalBnb = await convert(tokens)

            console.log(totalToken, TOtalBnb, 'BuyNftBuyNftBuyNftBuyNft');

            const TokenContract = new web3.eth.Contract(tokenABI, config.tokenAddress)

            const ApproveAmount = parseFloat(TOtalBnb)

            const estimateGas11 = await TokenContract.methods.approve(config.CasinoAddress, ApproveAmount.toString()).estimateGas({
                from: address.address
            })

            console.log(estimateGas11, 'estimateGas11estimateGas11');


            const approve = await TokenContract.methods.approve(config.CasinoAddress, ApproveAmount.toString()).send({
                from: address.address,
                gas: estimateGas11
            })

            console.log(approve, 'approviedapproviedapprovied');

            const casinoContract = await new web3.eth.Contract(casinoABI, config.CasinoAddress)
            console.log(casinoContract, 'casinoContractcasinoContractcasinoContractcasinoContract');


            const totalnoOfTOken = parseFloat(totalToken) * 1e18

            const result = await casinoContract.methods.buyTokens(totalnoOfTOken.toString()).send({
                from: address.address,
                value: TOtalBnb.toString(),
                gas: 3000000
            })
            if (result) {

                const status = true
                return status
            } else {
                const status = false

                return status
            }



        }


    } catch (err) {
        console.log(err);
    }


}


export async function PlayGame(startNum, endNum, tokenBet, address) {

    try {

        const CasinoContract = await new web3.eth.Contract(casinoABI, config.CasinoAddress)

        const TokenContract = new web3.eth.Contract(tokenABI, config.tokenAddress)

        const TokenAmount = (parseFloat(tokenBet)) * 1e18

        const estimateGas11 = await TokenContract.methods.approve(config.CasinoAddress, TokenAmount.toString()).estimateGas({
            from: address.address

        })

        console.log(estimateGas11, 'estimateGas11estimateGas11');


        const approve = await TokenContract.methods.approve(config.CasinoAddress, TokenAmount.toString()).send({
            from: address.address,
            gas: estimateGas11
        })

        console.log(approve, 'approviedapproviedapprovied');


        const estimateGas = await CasinoContract.methods.playGame(startNum, endNum, tokenBet).estimateGas({
            from: address.address
        })

        console.log(estimateGas, 'estimateGasestimateGasestimateGas11');
        const value = await convert(tokenBet * 1e18)
        console.log(value, 'tokenBet*1e18tokenBet*1e18');
        let returnObject;
        const result = await CasinoContract.methods.playGame(startNum.toString(), endNum.toString(), value.toString()).send({
            from: address.address,
            gas: 3000000
        }).then(res => {


            returnObject = {
                winNumber: res.events['RouletteGame'].returnValues.NumberWin,
                mustSpin: res.events['RouletteGame'].returnValues.result,
                tokensEarned: res.events['RouletteGame'].returnValues.tokensEarned
            }
            console.log(res.events['RouletteGame'].returnValues, 'resultresultresult', returnObject);

        }).catch(err => {
            console.log(err, 'errrrrrrrr');
        })
        return returnObject

    } catch (error) {
        console.log(error);
    }
}


export async function Widthdraw(amount, Numtoken, address) {

    try {

        const CasinoContract = await new web3.eth.Contract(casinoABI, config.CasinoAddress)

        const TokenContract = new web3.eth.Contract(tokenABI, config.tokenAddress)

        const TokenAmount = await convert(parseFloat(Numtoken) * 1e18)



        console.log(TokenAmount, 'TokenAmountTokenAmountTokenAmount');

        const estimateGas11 = await TokenContract.methods.approve(config.CasinoAddress, TokenAmount.toString()).estimateGas({
            from: address.address

        })

        console.log(estimateGas11, 'estimateGas11estimateGas11');


        const approve = await TokenContract.methods.approve(config.CasinoAddress, TokenAmount.toString()).send({
            from: address.address,
            gas: estimateGas11
        })

        console.log(approve, 'approviedapproviedapprovied');


        const estimateGas = await CasinoContract.methods.sellTokens(TokenAmount.toString()).estimateGas({
            from: address.address
        })

        console.log(estimateGas, 'estimateGasestimateGasestimateGas11');

        let returnStatus;
        const result = await CasinoContract.methods.sellTokens(TokenAmount.toString()).send({
            from: address.address,
            gas: 3000000
        }).then(res => {


            console.log(res, 'widthdraw Res');

            returnStatus = true

          


        }).catch(err => {

            console.log(err, 'errrrrrrrr');
            returnStatus = false
        })
        return returnStatus

    } catch (error) {
        console.log(error, 'errrr');
    }
}


