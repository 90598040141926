

import react from 'react'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav';
import {useNavigate} from 'react-router-dom'
import BuyTokens from './buytoken';


function Wallet() {


    const navigate=useNavigate()

    const handleSelect = (eventKey) => {
        console.log(eventKey,'eventKeyeventKey');
        if(eventKey=="1"){
            navigate('/buytoken')
        }else{
            navigate('/withdraw')
        }

    };



    return (
        <div>
            <Container sm={12} md={12}>
                {/* <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>

                            <Nav variant="pills" onSelect={handleSelect}>
                                <Nav.Item>
                                    <Nav.Link eventKey="1" href="#/home">
                                        Buy Tokens
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="2" title="Item">
                                        WithDraw Tokens
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>

                    </Col>
                </Row> */}
                <BuyTokens />
                
                {/* <Row >
                    <Row>

                        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <h1>
                                Token Stores
                            </h1>

                        </Col>

                    </Row>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <InputGroup style={{ width: '500px' }}>
                                <InputGroup.Text>Number Of Tokens</InputGroup.Text>
                                <Form.Control></Form.Control>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row>

                        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button style={{ width: '100px' }}>Buy </Button>
                        </Col>
                    </Row>

                    <Row>

                        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button style={{ width: '100px' }}>Buy </Button>
                        </Col>
                    </Row>

                    <Row>

                        <Col md={3} style={{ marginTop: '30px' }}>
                            <Button style={{ width: '100%' }}>Buy </Button>
                        </Col>
                        <Col md={3} style={{ marginTop: '30px' }}>
                            <Button style={{ width: '100%' }}>Buy </Button>
                        </Col>
                        <Col md={3} style={{ marginTop: '30px' }}>
                            <Button style={{ width: '100%' }}>Buy </Button>
                        </Col>
                        <Col md={3} style={{ marginTop: '30px' }}>
                            <Button style={{ width: '100%' }}>Buy </Button>
                        </Col>
                    </Row>
                </Row>
                 */}
            </Container>
        </div>
    )
}

export default Wallet