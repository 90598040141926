

import react, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row, Toast } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav';
import { useNavigate } from 'react-router-dom'
import { BuyNft, GetAccountBalance, getTokenPrice } from '../contractService';
import { useSelector } from 'react-redux';
import account from '../reduces/account';
import { toast } from 'react-toastify';
import { FieldValidation } from './common';


function BuyTokens() {

    const [noOfTokens, setnoOfTokens] = useState(0)
    const [tokenPrice, setTokenPrice] = useState('')
    const [errorValidation, seterrorValidation] = useState('')
    const account = useSelector(account => {
        return account.Account
    })

    const navigate = useNavigate()

    const handleSelect = (eventKey) => {
        console.log(eventKey, 'eventKeyeventKey');
        if (eventKey == "1") {
            navigate('/buytoken')
        } else {
            navigate('/withdraw')
        }

    };

    const changeHandler = (e) => {
        console.log(e);
        if (e.target.value == '') {
            setnoOfTokens('')
        }
        else if (isNaN(e.target.value)) {
            setnoOfTokens('')
        }
        setnoOfTokens(parseFloat(e.target.value))
    }



    const convertTokenPrice = async () => {

        const singlrTokenPrice = await getTokenPrice()
        console.log(singlrTokenPrice, noOfTokens, 'singlrTokenPricesinglrTokenPrice');
        if (isNaN(noOfTokens)) {
            return setTokenPrice(0)
        } else {

            setTokenPrice((noOfTokens * singlrTokenPrice) / 1e18)
        }

    }
    useEffect(() => {

        convertTokenPrice()
    }, [noOfTokens])

    console.log(noOfTokens, 'noOfTokensnoOfTokensnoOfTokensnoOfTokens');

    const changeHandlerone = (value) => {
        console.log(noOfTokens, isNaN(noOfTokens), value, 'changeHandleronechangeHandleronechangeHandlerone');
        if (isNaN(noOfTokens)) {
            setnoOfTokens(0)
            return false
        }
        setnoOfTokens(value)
    }

    const submitHandler = async (e) => {

        console.log('sun', noOfTokens, account);
        e.preventDefault();

        const AccountBal = await GetAccountBalance(account.address)
        const error = await FieldValidation(noOfTokens)


        if (Object.keys(error).length != 0) {

            seterrorValidation(error.Message)
            return false
        } else if (tokenPrice >= AccountBal) {
            toast.error('Your balance is low')
        }
        else {

            try {
                seterrorValidation('')
                const result = await BuyNft(noOfTokens, tokenPrice * 1e18, account)
                console.log(result, 'resultresultresultresult');

                if (result) {

                    toast.success(`You have purchased ${noOfTokens} of tokens`);
                    setTimeout(() => {
                        window.location.reload()
                    }, 3000)
                } else {
                    toast.error('User Rejected the request')
                }

            } catch (error) {
                console.log(error);
            }
        }
    }


    return (
        <div>
            <Container sm={12} md={12}>
                <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>

                            <Nav fill variant="pills" onSelect={handleSelect}>
                                <Nav.Item>
                                    <Nav.Link eventKey="1" >
                                        Buy Tokens
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="2" title="Item">
                                        WithDraw Tokens
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>

                    </Col>
                </Row>
                <Row >
                    <Row>

                        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <h1>
                                Token Stores
                            </h1>

                        </Col>

                    </Row>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <InputGroup style={{ width: '500px' }}>
                                <InputGroup.Text>Number Of Tokens</InputGroup.Text>
                                <Form.Control value={noOfTokens ? noOfTokens : ""} defaultValue={noOfTokens} onChange={(e) => { changeHandler(e) }} ></Form.Control>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', color: 'red' }}>
                            <span>
                                {
                                    errorValidation == '' ? '' : errorValidation
                                }
                            </span>
                        </Col>
                    </Row>

                    <Row>

                        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>

                            <InputGroup style={{ width: '300px' }}>

                                <InputGroup.Text>Token Price :</InputGroup.Text>
                                <Form.Control defaultValue={tokenPrice} disabled  ></Form.Control>
                            </InputGroup>

                        </Col>
                    </Row>

                    <Row>

                        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button style={{ width: '100px' }} onClick={(e) => submitHandler(e)}>Buy </Button>
                        </Col>
                    </Row>

                    <Row>

                        <Col md={3} style={{ marginTop: '30px' }}>
                            <Button style={{ width: '100%' }} onClick={() => changeHandlerone(noOfTokens + 10)} >10 </Button>
                        </Col>
                        <Col md={3} style={{ marginTop: '30px' }}>
                            <Button style={{ width: '100%' }} onClick={() => changeHandlerone(noOfTokens + 100)} >100 </Button>
                        </Col>
                        <Col md={3} style={{ marginTop: '30px' }}>
                            <Button style={{ width: '100%' }}
                                onClick={() => changeHandlerone(noOfTokens + 1000)} >1000 </Button>
                        </Col>
                        <Col md={3} style={{ marginTop: '30px' }}>
                            <Button style={{ width: '100%' }}
                                onClick={() => changeHandlerone(noOfTokens + 10000)} >10000 </Button>
                        </Col>
                    </Row>
                </Row>

            </Container>
        </div>
    )
}

export default BuyTokens