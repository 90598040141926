

import createStore from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import AccountSlice from './account'
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from "redux-persist/lib/storage";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import { thunk } from "redux-thunk";
import mainReducer from './mainstore';

// const customizedMiddleware = getDefaultMiddleware({
//     serializableCheck: false
//   })

const persistConfig = {
    key: "root",
    storage,
    stateReconciler: autoMergeLevel1,
    whitelist: ["Account"],
    blacklist: [],
    debug: true,
};

const store = configureStore({
    reducer: persistReducer(persistConfig, mainReducer()),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(thunk),
    devTools: true,
})


export default store