



import React, { useEffect, useState } from 'react'
import { Wheel } from 'react-custom-roulette'
import '../App.css'
import { Button, Col, Container, Form, InputGroup, Row, Toast, ToastBody } from 'react-bootstrap';
import { GetTokenBalance, PlayGame } from '../contractService';
import { useSelector } from 'react-redux';
import account from '../reduces/account';
import { FieldValidation } from './common';
import Spinner from 'react-bootstrap/Spinner';
import tenor from '../asserts/tenor-unscreen.gif'
import { toast } from 'react-toastify';

const data = [
    { id: 0, option: 0, style: { backgroundColor: 'green', textColor: 'white' } },
    { id: 1, option: 1, style: { backgroundColor: 'red', textColor: 'white' } },
    { id: 2, option: 8, style: { backgroundColor: 'black', textColor: 'white' } },
    { id: 3, option: 2, style: { backgroundColor: 'red', textColor: 'white' } },
    { id: 4, option: 9, style: { backgroundColor: 'black', textColor: 'white' } },
    { id: 5, option: 3, style: { backgroundColor: 'red', textColor: 'white' } },
    { id: 6, option: 10, style: { backgroundColor: 'black', textColor: 'white' } },
    { id: 7, option: 4, style: { backgroundColor: 'red', textColor: 'white' } },
    { id: 8, option: 11, style: { backgroundColor: 'black', textColor: 'white' } },
    { id: 9, option: 5, style: { backgroundColor: 'red', textColor: 'white' } },
    { id: 10, option: 12, style: { backgroundColor: 'black', textColor: 'white' } },
    { id: 11, option: 6, style: { backgroundColor: 'red', textColor: 'white' } },
    { id: 12, option: 13, style: { backgroundColor: 'black', textColor: 'white' } },
    { id: 13, option: 7, style: { backgroundColor: 'red', textColor: 'white' } },
    { id: 14, option: 14, style: { backgroundColor: 'black', textColor: 'white' } },
];




function Spinnerwheel() {


    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [noOfTokens, setnoOfTokens] = useState(0)
    const [tokenBalance, settokenBalance] = useState(0)
    const [ValidateError, setValidateError] = useState('')
    const [showToast, setshowToast] = useState(false);
    const [loader, setLoader] = useState(false)
    const account = useSelector(account => {
        return account.Account
    })

    const handleSpinClick = () => {
        if (!mustSpin) {
            const newPrizeNumber = Math.floor(Math.random() * data.length);
            console.log(newPrizeNumber, 'newPrizeNumbernewPrizeNumbernewPrizeNumbernewPrizeNumber');
            setPrizeNumber(newPrizeNumber);
            setMustSpin(true);
        }
    }

    const changeHandler = (e) => {
        console.log(e.target.value, 'e.target.valuee.target.value');
        if (e.target.value == '') {
            return setnoOfTokens(0)
        }

        setnoOfTokens(e.target.value)
    }

    const changeHandlerOne = (value) => {
        console.log(value, 'valuevaluevaluevalue');

        setnoOfTokens(value)
    }
    useEffect(() => { }, [noOfTokens, ValidateError])

    const submitHandler = async (e, startNum, EndNum) => {

        e.preventDefault()
        console.log(startNum, EndNum, 'startNum,EndNumstartNum,EndNumstartNum,EndNum');

        const error = await FieldValidation(noOfTokens)
        console.log(error, 'errorerrorerrorerror');
        if (Object.keys(error).length != 0) {
            setValidateError(error.Message)
            return false
        }

        const tokenBalance = await GetTokenBalance(account.address)
        if (noOfTokens >= parseFloat(tokenBalance / 1e18)) {
            setValidateError('value is greater then your account token Balance')
            return false
        }

        setValidateError('')
        const result = await PlayGame(startNum, EndNum, noOfTokens, account)

        console.log(result, 'Spinnerresult');
        if (result != undefined) {

            const dat = await data.find(res => {
                if (res.option == parseInt(result.winNumber)) {
                    return res
                }
            })

            console.log(dat, 'datadata');

            if (result.tokensEarned != "0") {

                console.log('r1');
                setPrizeNumber(dat.id);
                toast.success(`you have won ${result.tokensEarned/1e18}`)
                setMustSpin(true);
                setshowToast(true)

            } else {
                console.log('r2');
                setPrizeNumber(dat.id);
                setMustSpin(true);
            }
        } else {
            
        }


    }


    const getAccounttokenBalance = async (e) => {

        const price = await GetTokenBalance(account.address)
        console.log(price, 'pricepriceprice   ');
        settokenBalance(price / 1e18)
        setnoOfTokens(price / 1e18)
    }


    return (
        <div>
            {/* <Row>
                <Col className='Spinner'>
                    <Spinner animation="border" />
                </Col>
            </Row> */}

            {



                <Container fluid>

                    {/* <div className='loader'>
                        <div>

                            <img src={tenor} alt='wings' />
                        </div>
                    </div> */}
                    


                    <Row>
                        <Col>
                            <div className='divcointainetr'>


                                <div className='text-center butn'>

                                    <Wheel
                                        mustStartSpinning={mustSpin}
                                        prizeNumber={prizeNumber}
                                        data={data}
                                        
                                        onStopSpinning={() => {
                                            setMustSpin(false);
                                        }}
                                        outerBorderColor={"#757272"}
                                        outerBorderWidth={15}

                                        // innerBorderWidth={2}
                                        radiusLineWidth={7}
                                        radiusLineColor={"#757272"}
                                        spinDuration={0.3}
                                    // pointerProps={}

                                    />

                                    <button>SPIN</button>

                                </div>


                            </div >
                        </Col>
                        <Row>

                            {/* <div className='text-center'>{
                                !mustSpin ? data[prizeNumber].option : "0"
                            }</div> */}
                            <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                <InputGroup style={{ width: '400px' }}>
                                    <InputGroup.Text>Amount Of Tokens</InputGroup.Text>
                                    <Form.Control value={noOfTokens ? noOfTokens : ''} defaultValue={noOfTokens} onChange={(e) => changeHandler(e)} ></Form.Control>
                                </InputGroup>


                            </Col>

                            <Row>
                                <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>


                                    {
                                        ValidateError == '' ? '' :
                                            <span style={{ color: 'red' }}>{ValidateError}</span>
                                    }
                                </Col>
                            </Row>

                        </Row>
                        <Row>
                            <Col>
                                <Button variant='dark' style={{ width: '100%', marginTop: '20px' }}
                                    onClick={() => changeHandlerOne(parseInt(noOfTokens) + 10)}
                                >+10</Button>
                            </Col>
                            <Col>
                                <Button variant='dark' style={{ width: '100%', marginTop: '20px' }} onClick={() => changeHandlerOne(parseInt(noOfTokens) + 100)}>+100</Button>
                            </Col>
                            <Col>
                                <Button variant='dark' style={{ width: '100%', marginTop: '20px' }} onClick={() => changeHandlerOne(parseInt(noOfTokens) + 1000)}>+1000</Button>
                            </Col>
                            <Col>
                                <Button variant='dark' style={{ width: '100%', marginTop: '20px' }} onClick={() => changeHandlerOne(parseInt(noOfTokens) * 2)}>x2</Button>
                            </Col>
                            <Col>
                                <Button variant='dark' style={{ width: '100%', marginTop: '20px' }} onClick={() => changeHandlerOne(parseInt(noOfTokens) / 2)}>+1/2</Button>
                            </Col>
                            <Col>
                                <Button variant='dark' style={{ width: '100%', marginTop: '20px' }}
                                    onClick={() => getAccounttokenBalance()}
                                >MAX</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant='dark' style={{ width: '100%', marginTop: '20px' }}
                                    onClick={(e) => submitHandler(e, 1, 7)}
                                >1-7</Button>
                            </Col>
                            <Col>
                                <Button variant='dark' style={{ width: '100%', marginTop: '20px' }}
                                    onClick={(e) => submitHandler(e, 0, 0)}
                                >0</Button>
                            </Col>
                            <Col>
                                <Button variant='dark' style={{ width: '100%', marginTop: '20px' }}
                                    onClick={(e) => submitHandler(e, 8, 14)}>8-14</Button>
                            </Col>
                        </Row>
                    </Row>
                </Container>
            }


        </div>
    )
}

export default Spinnerwheel