
import react, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Nav, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { GetTokenBalance, Widthdraw, getTokenPrice } from '../contractService'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
function WidthDraw() {

    const [noOfTokens, setnoOfTokens] = useState(0)
    const [tokenBalance, settokenBalance] = useState(0)
    const [tokenPrice, setTokenPrice] = useState(0)
    const Address = useSelector(account => {
        return account.Account
    })

    console.log(Address, 'AddressAddressAddressAddress');
    const navigate = useNavigate()

    const handleSelect = (eventKey) => {
        console.log(eventKey, 'eventKeyeventKey');
        if (eventKey == "1") {
            navigate('/buytoken')
        } else {
            navigate('/withdraw')
        }

    };

    const changeHandler = e => {
        setnoOfTokens(e.target.value)
    }

    const changeHandlerone = (value) => {

        setnoOfTokens(value)
        setTokenPrice()
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        if (noOfTokens != '' || 0) {

            const price = await getTokenPrice()
            console.log(price, 'priceprice');

            console.log(parseFloat(price / 1e18), parseFloat(noOfTokens), 'noOfTokensnoOfTokensnoOfTokens');

            const amount = parseFloat(price / 1e18) * parseFloat(noOfTokens)



            const result = await Widthdraw(amount, noOfTokens, Address)

            console.log(result, 'resultresultresult');
            if (result) {
                toast.success(`completed withdraw of bnb for ${noOfTokens}`)

                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            } else {
                toast.error('user rejected ')
            }

        }
    }

    useEffect(() => {



    }, [noOfTokens])

    const getAccountTokenBalance = async () => {
        const price = await GetTokenBalance(Address.address)
        console.log(price, 'pricepriceprice');


        settokenBalance(price / 1e18)
    }

    return (
        <div>
            <Container>

                <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>

                            <Nav variant="pills" onSelect={handleSelect}>
                                <Nav.Item>
                                    <Nav.Link eventKey="1" href="#/home">
                                        Buy Tokens
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="2" title="Item">
                                        WithDraw Tokens
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <h1>WithDraw Token</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button style={{ width: '100%' }}
                                onClick={() => changeHandlerone(parseInt(noOfTokens) + 10)}
                            >+10</Button>
                        </Col>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button style={{ width: '100%' }} onClick={() => changeHandlerone(parseInt(noOfTokens) + 100)}>+100</Button>
                        </Col>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button style={{ width: '100%' }} onClick={() => changeHandlerone(parseInt(noOfTokens) + 1000)}>+1000</Button>
                        </Col>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button style={{ width: '100%' }} onClick={() => changeHandlerone(parseInt(noOfTokens) * 2)}>x2</Button>
                        </Col>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button style={{ width: '100%' }}
                                onClick={() => changeHandlerone(parseInt(noOfTokens) / 2)}
                            >1/2</Button>
                        </Col>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button style={{ width: '100%' }}
                                onClick={() => getAccountTokenBalance()}
                            >MAX</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <InputGroup style={{ width: '400px' }}>
                                <InputGroup.Text>Amount Of Tokens</InputGroup.Text>

                                <Form.Control value={noOfTokens ? noOfTokens : ''} defaultValue={noOfTokens} onChange={(e) => changeHandler(e)}></Form.Control>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>

                        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button style={{ width: '100px' }} onClick={(e) => submitHandler(e)}>Buy </Button>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </div>
    )
}

export default WidthDraw