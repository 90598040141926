
// let key={}

// const env='local'

// if(env=='production'){

//     key={
       
//         tokenAddress: '0x68E52fa94e5987AB6454Ec6CaEa53a20b557F363', 
//         CasinoAddress: '0xa867625756A21Cd3844c9D4e599f1cc08bd3e16e' //0xbC7E0B12eB0073def687BcC1Bf551b2D0eFB358C //0xA786091928B05D8EbA929EcD3d367205217953E6
//     }
// }else {

//     key={
       
//         tokenAddress: '0x68E52fa94e5987AB6454Ec6CaEa53a20b557F363', 
//         CasinoAddress: '0xa867625756A21Cd3844c9D4e599f1cc08bd3e16e' //0xbC7E0B12eB0073def687BcC1Bf551b2D0eFB358C //0xA786091928B05D8EbA929EcD3d367205217953E6
//     }
    
// }


// export default key

let NODE_ENV = "production";

let Front_URL = "";
let rpcURL = "";
let decimalvalues = 1000000000000000000;
let toFixed = 4;
let networkVersion = "";
let bscurl = "";
let adminAddress = "";
let CasinoAddress = "";
let tokenAddress = "";
let chainId = "";
let Server_URL = "";
// let projectId = "";
const ownerAddr = "";

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined
};

if (NODE_ENV == "demo") {
//   Front_URL = "https://www.zeus.eu.com/";
//   networkVersion = 56; //56
//   rpcURL = "https://bsc-dataseed.binance.org/";
//   bscurl = "https://bscscan.com/";
//   adminAddress = "0xFF87Deaf12627DAe49F27228075a085E5098750F";
//   mlmcontract = "0x7e07401f537582ac40fa9f5Ae8Bc93Ef51248700";
//   usdtcontract = "0x55d398326f99059fF775485246999027B3197955";
//   chainId = 56; //56
//   projectId = "cb89ebb21cdccb2e1b591e189e27706a"; //wallet connect
} else if (NODE_ENV == "demo") {
  Front_URL = "https://spinning-wheel-frontend.pages.dev/";
  networkVersion = 80001;
//   rpcURL = "https://bsc-testnet.publicnode.com/";
//   bscurl = "https://testnet.bscscan.com/";
  adminAddress = "0x80Aa595c83430288E3A75F4D03bE15b72385420F";
  CasinoAddress= '0xa867625756A21Cd3844c9D4e599f1cc08bd3e16e' //0xbC7E0B12eB0073def687BcC1Bf551b2D0eFB358C //0xA786091928B05D8EbA929EcD3d367205217953E6
  tokenAddress= '0x68E52fa94e5987AB6454Ec6CaEa53a20b557F363';
  chainId = 80001; //80001
//   projectId = "cb89ebb21cdccb2e1b591e189e27706a"; //wallet connect
} else {
  Front_URL = "http://localhost:3000/";
  Server_URL = "http://localhost:2053/";
  networkVersion = 80001;
//   rpcURL = "https://endpoints.omniatech.io/v1/bsc/testnet/public";
//   bscurl = "https://testnet.bscscan.com/";
  adminAddress = "0x80Aa595c83430288E3A75F4D03bE15b72385420F";
  CasinoAddress= '0xa867625756A21Cd3844c9D4e599f1cc08bd3e16e' //0xbC7E0B12eB0073def687BcC1Bf551b2D0eFB358C //0xA786091928B05D8EbA929EcD3d367205217953E6
  tokenAddress= '0x68E52fa94e5987AB6454Ec6CaEa53a20b557F363';
  chainId = 80001; //80001
//   projectId = "cb89ebb21cdccb2e1b591e189e27706a"; //wallet connect
}

let key = {
  Front_URL: Front_URL,
  Server_URL: Server_URL,
  decimalvalues: decimalvalues,
  toFixed: toFixed,
  rpcURL: rpcURL,
  networkVersion: networkVersion,
  toasterOption: toasterOption,
  adminAddress: adminAddress,
  CasinoAddress: CasinoAddress,
  tokenAddress: tokenAddress,
  bscurl: bscurl,
  chainId: chainId,
//   projectId: projectId
};

export default key;




// tokenAddress:'0x001659d1285b7a2a5bc5a0c2c652df7561f48332',
// CasinoAddress:'0xa441ade20a2bdd2669d81bd8e222842b4ddfa71c'