import React, { useEffect, useState } from 'react'
import { Wheel } from 'react-custom-roulette'
import './App.css'
import Headers from './components/header'
import {
  Routes,
  Route,
} from 'react-router-dom'
import Wallet from './components/wallet'
import WidthDraw from './components/widthDraw'
import Spinner from './components/spinner'
import BuyTokens from './components/buytoken'
import account from './reduces/account'
import { useSelector } from 'react-redux'
import { GetTokenBalance } from './contractService'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default () => {

  const [balance, setbalance] = useState(0)

  const account = useSelector(account => {
    return account.Account
  })

  console.log(account, 'accountaccountaccountapp.js');
  const getLoader = async () => {
    const tokenbalance = await GetTokenBalance(account.address)
    setbalance(tokenbalance)

  }
  useEffect(() => {
    if (account.address != '') {

      getLoader()
    }else{
      setbalance(0)
    }
  }, [account])



  return (
    <>
      <ToastContainer />
      <Headers account={account} balance={balance} />
      <Routes>
        <Route path='/wallet' element={<Wallet />} />
        <Route path='/withdraw' element={<WidthDraw />} />
        <Route path='/buytoken' element={<BuyTokens />} />
        <Route path='/Spinner' element={<Spinner />} />
      </Routes>
    </>
  )
}



